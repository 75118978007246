<template>
  <div class="auth__form__content" id="auth-login">
    <h2 class="has-text-centered auth-sign-in-title">Sign in</h2>

    <a :href="spaceIdUrl" class="button-login-redirect">
      Sign in with Space ID
    </a>

    <!-- <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(doLogin)">
          <validation-provider
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-field
              label="Email"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input
                v-model="email"
                :value="email"
                placeholder="e.g: admin@roketin.com"
                required
              ></b-input>
            </b-field>
          </validation-provider>

          <validation-provider
            name="Password"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              label="Password"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input
                v-model="password"
                type="password"
                placeholder="password"
                required
              ></b-input>
            </b-field>
          </validation-provider>
          <b-field class="is-flex is-justify-content-flex-end"> -->
    <!-- <b-checkbox>Remember Me</b-checkbox> -->
    <!-- <router-link to="password-reset" class="is-link">
              Forgot Password
            </router-link>
          </b-field>
        </form>
      </validation-observer> -->

    <!-- <b-field class="is-flex is-justify-content-center">
        <b-button class="is-fullwidth" @click="doLogin()">Login</b-button>
      </b-field> -->
    <!-- <div class="auth__form__footer is-flex is-justify-content-center">
      <router-link to="registration" class="is-link">Register</router-link>
    </div> -->
  </div>
</template>

<script>
import { showToast } from '@/services/util'
export default {
  data() {
    return {
      email: '',
      password: '',
      message: '',
      isLoading: false,
      isCardModalActive: false,
      spaceIdUrl: process.env.VUE_APP_SPACEID_URL,
    }
  },
  methods: {
    goToForgotPassword() {
      this.$router.push('/forgot-password')
    },
    async doLogin() {
      this.isLoading = true

      let credential = {
        email: this.email,
        password: this.password,
        redirect: '/universe',
      }
      try {
        this.isLoading = true
        await this.$store.dispatch('auth/login', credential)

        // generate firebase token

        this.$emit('authenticated')
      } catch (err) {
        this.message = 'Login Failed'
        showToast('Login Failed', 'is-danger', 'is-bottom')
      }
      this.isLoading = false
    },
  },
}
</script>
